import axios from 'axios';
import { ApiError, ApiRedirect, ApiSecurityError } from 'helpers/error';
import { CONTENT_API_URL, DOMAIN_PORT, DOMAIN_PROTOCOL } from './constants';

type BuildHeadersParams = {
	domain: string;
	preview?: boolean;
	previewData?: Models.PreviewData;
	accessToken?: string;
};
export const buildHeaders = ({ domain, preview, previewData, accessToken }: BuildHeadersParams): Record<string, string> => {
	const headers = {};
	headers['Content-Type'] = 'application/json';
	headers['Hostname'] = domain;

	if (preview && typeof previewData === 'object') {
		headers['Authorization'] = previewData?.data?.auth;
	} else if (accessToken) {
		headers['Authorization'] = `Bearer ${accessToken}`;
	}

	return headers;
};

interface GetUmbracoContentParams {
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	url?: string;
	query?: string;
	method: 'GET' | 'POST';
	accessToken?: string;
}
export const getUmbracoContent = async <TResponse = any>({
	host,
	preview,
	previewData,
	method,
	query,
	url,
	accessToken,
}: GetUmbracoContentParams): Promise<TResponse> => {
	if (!CONTENT_API_URL) {
		throw new ApiError('CONTENT_API_URL:NOTDEFINED', 500, 'Content api url is not defined');
	} else if (url === 'umbraco') {
		throw new ApiError('PageNotFound', 404, 'Page not found');
	}

	if (host === 'localhost' && process.env.FALLBACK_HOSTNAME) {
		host = process.env.FALLBACK_HOSTNAME;
	}

	const domain = `${DOMAIN_PROTOCOL ? `${DOMAIN_PROTOCOL}://` : ''}${host}${DOMAIN_PORT ? `:${DOMAIN_PORT}` : ''}/`;

	const headers = buildHeaders({ domain, preview, previewData, accessToken });

	return axios({
		url: encodeURI(`${CONTENT_API_URL}${url ? url : ''}${preview ? '?preview=1' : ''}`),
		method,
		maxRedirects: 0,
		headers,
		data: {
			query,
		},
	})
		.then((res) => {
			if (method === 'POST' && res.data.errors) {
				throw new Error(res.data.errors[0].message);
			}
			return {
				...(method === 'GET' ? res.data : res.data.data),
				preview: Boolean(preview),
				previewData: previewData ? previewData : null,
			};
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			const statusCode = error?.response?.status || 500;
			const content = error?.response?.data?.data ?? error;

			if (statusCode === 404) {
				throw new ApiError('PageNotFound', 404, 'Page not found');
			} else if (statusCode === 301 || statusCode === 302) {
				throw new ApiRedirect(statusCode, error.response.data.url);
			} else if (statusCode === 500) {
				throw new ApiError('InternalServerError', 500, message);
			} else if (statusCode === 403) {
				throw new ApiSecurityError('Access denied', 403, message, content);
			} else if (statusCode === 401) {
				throw new ApiSecurityError('Unauthorized', 401, message, content);
			} else {
				throw new ApiError('Unknown error', statusCode, message);
			}
		});
};
