const CONTENT_API_URL = process.env.CONTENT_API_URL
	? process.env.CONTENT_API_URL?.endsWith('/')
		? process.env.CONTENT_API_URL
		: `${process.env.CONTENT_API_URL}/`
	: undefined;

const DOMAIN_PROTOCOL = process.env.DOMAIN_PROTOCOL ?? 'https';
const DOMAIN_PORT = process.env.DOMAIN_PORT;

export { CONTENT_API_URL, DOMAIN_PROTOCOL, DOMAIN_PORT };
