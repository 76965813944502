import React from 'react';
import { PageTypeMapFeature } from 'features/Mappers/PageType/PageTypeFeature';
import { ErrorPage } from 'ui/components/5-templates/ErrorPage';
import { LayoutFeature } from 'features/Layouts/Layout/LayoutFeature';
import { Dictionary, DictionaryContext } from 'application/adapters/context/Dictionary';
import { PageContext, Page as PageContent } from 'application/adapters/context/PageContext';
import { Site, SiteContext } from 'application/adapters/context/SiteContext';
import useSWR from 'swr';
import { get404Content } from 'application/repositories/pageContentRepository';

const Page: React.FC = () => {
	const { data, error } = useSWR('string', get404Content);

	const isLoading = !data && !error;

	if (isLoading) return null;

	if (!data || error) return <ErrorPage errorCode={404} message={'The requested ressource could not be found'} />;

	const { dictionary, page, root, host } = data ?? {};
	const { culture } = page ?? {};
	const { siteSettings } = root?.properties ?? {};

	const dictionaryProvider = new Dictionary(dictionary, culture);
	const site = new Site(root, host, culture, siteSettings);
	const pageContent = new PageContent(data);

	return (
		<SiteContext.Provider value={site}>
			<DictionaryContext.Provider value={dictionaryProvider}>
				<PageContext.Provider value={pageContent}>
					<LayoutFeature {...data}>
						<PageTypeMapFeature {...data?.page} />
					</LayoutFeature>
				</PageContext.Provider>
			</DictionaryContext.Provider>
		</SiteContext.Provider>
	);
};

export default Page;
