import { getCulture } from 'application/repositories/cultureRepository';
import { getUmbracoContent } from 'application/adapters/umbraco/umbracoAdapter';
import axios from 'axios';
import { ApiError } from 'helpers/error';

interface GetPageContentParams {
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	url: string;
	accessToken?: string;
}
export interface PageContentResponse {
	root: {
		name:string;
		properties: Umbraco.SiteSetting;
		header: Umbraco.HomePage[];
	};
	page: Umbraco.HomePage;
	siteTheme: Umbraco.SiteTheme[];
	footer: {
		pageFooter: Umbraco.FooterCard[];
	};
	dictionary: Umbraco.DictionaryItem[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	breadcrumbs?: Models.breadCrumbData[];
}
export const getPageContent = async ({ url, host, preview, previewData, accessToken }: GetPageContentParams): Promise<PageContentResponse> => {
	const query = `{
		root: page(path: "/") {
			properties(fields: ["siteSettings","noIndex"]) 
			name
			header: children {
				name
				url
				id
				properties(fields: ["pageSettings", "title"]) 
				
			}
			documentType
		}	
		page: page {
			id
			name
			documentType
			url
			properties
			template
			culture
			authResult
		}
		siteTheme: pageSettings(settings: [{documentTypeAlias: "siteTheme", inherit: true}]) {
			documentType
			content {
				documentType
				properties
			}
			settings {
				documentType
				properties
				
			}
		}
		breadcrumbs: breadcrumb 
		footer: pageFooter(inherit: true)
		dictionary: dictionary {
			key
			values {
				culture
				value
			}
		}
	}`;
	return await getUmbracoContent({
		host,
		query,
		method: 'POST',
		preview,
		previewData,
		url,
		accessToken: accessToken,
	});
};

export const get404Content = async (): Promise<any> => {
	try {
		const res = await axios('/api/get404content');
		return res.data;
	} catch (err) {
		const errorCode = err?.response?.status ?? 500;
		const errorMessage = err?.message ?? 'Unknown error';
		throw new ApiError('[genericRequest]', errorCode, `Request to '/404 threw an error: ${errorMessage}`);
	}
};
