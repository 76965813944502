import { Main } from 'ui/components/4-habitats/Main';
import { Header } from 'ui/components/4-habitats/Header';
import { Footer } from 'ui/components/4-habitats/Footer';
import { HeroCard } from 'ui/components/2-molecules/Card/HeroCard';

export interface ErrorPageProps {
	errorCode: 404 | 500;
	message: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ errorCode, message }) => {
	return (
		<>
			<Header />
			<Main>
				<HeroCard heading={errorCode.toString()} kicker={message} />
			</Main>
			<Footer />
		</>
	);
};
